<template>
  <div class="item">
    <RouterLink :to="{name: 'order-detail', params: {id: order.id}}">
      <ul class="col2 order_bar">
        <li class="col2L buy_date"><img src="@/assets/img/mypage/icon_calendar.png" alt="カレンダーアイコン">
          {{ order.created_at | formatDate }}
        </li>
        <li class="col2R buy_time">
          {{ order.created_at | formatTime }}
        </li>
      </ul>
      <ul class="col2 item_inner">
        <li class="col2L">
          <div class="item_photo"><img src="@/assets/img/mypage/item_photo.jpg" alt="商品画像"></div>
        </li>
        <li class="col2R flex_g1">
          <div class="order_num">{{ ( '0000000000' + order.id ).slice( -10 ) }}</div>
          <div class="order_name">{{ order.last_name }} {{ order.first_name }} 様</div>
          <ul class="flex between align_end">
            <li>
              <!--
              <div class="order_volume">在庫：{{ order.value }}</div>
              -->
              <div class="order_price">{{ order.price | currency }}<span>税込</span></div>
            </li>
            <li class="order_states_off" v-if="!isSend">未発送</li>
            <li class="order_states_on" v-if="isSend">発送</li>
          </ul>
        </li>
      </ul><!--.col2-->
    </RouterLink>
  </div><!-- .item -->
</template>

<script>
import moment from 'moment';
export default {
  props: {
    order: Object,
  },
  filters: {
    formatDate (val) {
      return moment(val).format('YYYY/MM/DD');
    },
    formatTime (val) {
      return moment(val).format('HH:mm');
    }
  },
  computed: {
    isSend() {
      return this.order.send_at;
    }
  }
}
</script>
