<template>
  <div id="orders">
    <article id="wrap">
      <AppHeader theme="white">わたしのこと</AppHeader>
      <div class="undelivered" v-if="isArtist && filteredOrderUnShipped.length > 0">
        <a @click="$refs.tab2_label.click();">未配送：{{ filteredOrderUnShipped.length }}件</a>
      </div>

      <section class="tab_list pb_150">

        <div class="tab_wrap">
          <input id="tab1" type="radio" name="tab_btn">
          <input id="tab2" type="radio" name="tab_btn" checked="">
          <input id="tab3" type="radio" name="tab_btn">

          <div class="tab_area_bg order"></div>
          <div class="tab_area order">
            <label class="tab1_label" for="tab1">すべて</label>
            <label class="tab2_label" for="tab2" ref="tab2_label">未発送</label>
            <label class="tab3_label" for="tab3">発送済</label>
          </div>

          <div class="panel_area">
            <div id="panel1" class="tab_panel">
              <ArtistOrder :order="order" v-for="order in orders" :key="order.id"></ArtistOrder>
            </div><!--#panel1-->

            <div id="panel2" class="tab_panel">

              <ArtistOrder :order="order" v-for="order in filteredOrderUnShipped" :key="order.id"></ArtistOrder>

            </div><!--#panel2-->

            <div id="panel3" class="tab_panel">

              <ArtistOrder :order="order" v-for="order in filteredOrderSend" :key="order.id"></ArtistOrder>

            </div><!--#panel3-->

          </div><!--.panel_area-->

        </div><!--.tab_wrap-->

      </section>

      <AppFooter theme="yellow" fixed></AppFooter>

    </article>
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
import ArtistOrder from '@/components/ArtistOrder';
import moment from 'moment';
export default {
  components: { AppHeader, AppFooter, ArtistOrder },
  data () {
    return {
      orders: [],
    }
  },
  computed: {
    isArtist () {
      return !!this.$store.state.loginUser.artist_id;
    },
    filteredOrderSend () {
      return this.orders.filter(order => order.send_at)
    },
    filteredOrderUnShipped () {
      return this.orders.filter(order => !order.send_at)
    },
  },
  filters: {
    formatDate (val) {
      return moment(val).format('YYYY/MM/DD');
    },
    formatTime (val) {
      return moment(val).format('HH:mm');
    }
  },
  created () {
    this.initialize();
  },
  methods: {
    async initialize () {
      const response = await this.$http.get(`orders`);
      this.orders = response.data.data;
    }
  }
}
</script>
